import { Fragment, useState, useEffect } from "react";
import { useHistory, Link, useParams } from "react-router-dom";
import { Dialog, Menu, Transition, Tab } from "@headlessui/react";
import LeftNavigation from "../../../components/LeftNavigation/leftNavigation.js";
import Header from "../../../components/Header/Header";
import BtnRedirect from "../../../components/Form/Button/BtnRedirect";
import BtnSaveRecord from "../../../components/Form/Button/BtnSaveRecord";
import {
  ClockIcon,
  CogIcon,
  CreditCardIcon,
  DocumentChartBarIcon,
  HomeIcon,
  QuestionMarkCircleIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import config from "../../../utils/config.js";
import { validateForm, logFormData } from "../../../utils/common.js";
import { toast } from "react-toastify";

const navigation = [
  { name: "Home", href: "#", icon: HomeIcon, current: true },
  { name: "History", href: "#", icon: ClockIcon, current: false },
  { name: "Balances", href: "#", icon: ScaleIcon, current: false },
  { name: "Cards", href: "#", icon: CreditCardIcon, current: false },
  { name: "Recipients", href: "#", icon: UserGroupIcon, current: false },
  { name: "Reports", href: "#", icon: DocumentChartBarIcon, current: false },
];
const secondaryNavigation = [
  { name: "Settings", href: "#", icon: CogIcon },
  { name: "Help", href: "#", icon: QuestionMarkCircleIcon },
  { name: "Privacy", href: "#", icon: ShieldCheckIcon },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function Roles({ history }) {
  const [data, setData] = useState([]);
  const [error, setError] = useState({});
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [info, setInfo] = useState({});
  const [roleList, setRoleList] = useState([]);
  const { id } = useParams();
  const [pass, setPass] = useState({});
  const [removedImage, setRemovedImage] = useState("");
  const [areaList, setAreaList] = useState();

  function getRoleList() {
    config.getAPI({ url: "role/list", params: { status: "Active" } })
      .then((data) => {
        if (data.payload) {
          setRoleList(data.payload.rows);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  const getAreaList = () => {
    config
      .getAPI({ url: "department/list", params: {} })
      .then((data) => {
        if (data.payload) {
          setAreaList(data.payload.rows);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    const getDetails = async () => {
      const detailInfo = await config.getAPI({
        url: `user/getdatabyId`,
        params: { id: id },
      });
      if (detailInfo?.payload) {
        setPass(detailInfo?.payload?.password);
        setInfo({
          ...detailInfo?.payload,
          password: "",
        });
      }
      if (detailInfo?.error?.message) {
        history.push("/");
        // return toast.error(data?.error?.message)
      }
    };
    getDetails();
  }, [id]);

  useEffect(() => {
    getRoleList();
    getAreaList();
  }, []);

  const handleChange = (e, name) => {
    if (name === "profile") {
      // setShowLoader(true)
      const setData = {
        ...info,
        [name]: e?.target?.files?.[0],
      };

      // setFrmsrc(URL.createObjectURL(e?.target?.files?.[0]))
      setInfo(setData);
      // setShowLoader(false)
    } else {
      const setData = {
        ...info,
        [name]: e?.target?.value,
      };
      setInfo(setData);
    }
  };

  const handleProfileRemove = async () => {
    if (info?.profile) {
      if (typeof info?.profile === "string" && info.avatar !== "") {
        setRemovedImage(info?.profile);
      }
      setInfo({
        ...info,
        profile: "",
      });
    }
  };
  const handleSubmit = async () => {
    try {
      const includedFields = [
        "name",
        "email",
        "phone_number",
        "role_id",
        "status",
        "profile",
      ];
      const sendData = {
        ID: info?.ID,
        fields: {
          ...info,
          password: pass,
        },
      };
      let formData = new FormData();
      formData.append("fields", JSON.stringify(sendData));
      const validationResponse = validateForm(info, includedFields);
      setError(validationResponse?.errors);
      if (removedImage !== "") {
        sendData["removedImage"] = removedImage;
      }
      formData.append("id", id);
      formData.append("file", info?.profile);
      logFormData(formData);
      delete sendData?.fields?.id;
      if (validationResponse?.isValid) {
        const insertResponse = await config.postFormDataAPI({
          url: "user/update",
          params: formData,
        });
        if (insertResponse?.response?.data?.error?.message) {
          return toast.error(insertResponse?.response?.data?.error?.message);
        }
        if (
          insertResponse?.payload?.errors ||
          insertResponse?.payload?.email === "SequelizeUniqueConstraintError"
        ) {
          return toast.error("Este registo já existe");
        }
        setTimeout(() => {
          return toast.success("Registo criado com sucesso");
        }, 200);
        history.push("/users/ViewUsers");
      }
    } catch (error) {
      return toast.error(error?.message);
    }
  };
  const handleSubmitPwd = async () => {
    try {
      const includedFields = ["password", "cpassword"];
      const validationResponse = validateForm(info, includedFields);
      setError(validationResponse?.errors);
      if (validationResponse?.isValid) {
        const insertResponse = await config.postAPI({
          url: "user/update",
          params: {
            fields: {
              ...info,
              type: "change_password",
            },
            id,
          },
        });
        if (insertResponse?.response?.data?.error?.message) {
          return toast.error(insertResponse?.response?.data?.error?.message);
        }
        setTimeout(() => {
          return toast.success("Registo criado com sucesso");
        }, 200);
        history.push("/users/ViewUsers");
      }
    } catch (error) {
      return toast.error(error?.message);
    }
  };
  return (
    <>
      <div className="min-h-full">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-skin-secondary pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-shrink-0 items-center px-4">
                    <img
                      className="h-8 w-auto"
                      src="https://tailwindui.com/img/logos/mark.svg?color=cyan&shade=300"
                      alt="Easywire logo"
                    />
                  </div>
                  <nav
                    className="mt-5 h-full flex-shrink-0 divide-y divide-cyan-800 overflow-y-auto"
                    aria-label="Sidebar"
                  >
                    <div className="space-y-1 px-2">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "bg-skin-primary text-white"
                              : "text-white hover:text-white left-menu-btn",
                            "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          <item.icon
                            className="mr-4 h-6 w-6 flex-shrink-0 text-white"
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      ))}
                    </div>
                    <div className="mt-6 pt-6">
                      <div className="space-y-1 px-2">
                        {secondaryNavigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              item.current
                                ? "bg-skin-primary text-white"
                                : "text-white hover:text-white left-menu-btn",
                              "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                            )}
                          >
                            <item.icon
                              className="mr-4 h-6 w-6 text-white"
                              aria-hidden="true"
                            />
                            {item.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  </nav>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <LeftNavigation />
        </div>

        <div className="flex flex-1 flex-col lg:pl-64">
          <Header />
          <main className="flex-1">
            <div className="mt-8 page_bg bg_borderradius pb-12">
              <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8">
                <div className="py-5 flex items-center justify-between">
                  <h1 className="text-3xl font-medium tracking-tight dark:text-[#fff] text-black">
                    Editar Utilizadores
                  </h1>
                </div>

                <Tab.Group as="div">
                  <div className="border-b border-gray-400">
                    <Tab.List className="-mb-px flex space-x-8">
                      <Tab
                        className={({ selected }) =>
                          classNames(
                            selected
                              ? "border-red-600 text-red-600"
                              : "border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-300",
                            "whitespace-nowrap border-b-2 py-6 text-base font-medium"
                          )
                        }
                      >
                        Utilizadores
                      </Tab>
                      <Tab
                        className={({ selected }) =>
                          classNames(
                            selected
                              ? "border-red-600 text-red-600"
                              : "border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-300",
                            "whitespace-nowrap border-b-2 py-6 text-base font-medium"
                          )
                        }
                      >
                        Alterar Senha
                      </Tab>
                    </Tab.List>
                  </div>
                  <Tab.Panels as={Fragment}>
                    <Tab.Panel className="mt-6">
                      <form
                        className="space-y-6"
                        action="#"
                        method="POST"
                        enctype="multipart/form-data"
                      >
                        <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
                          <div className="md:grid md:gap-6">
                            <div className="mt-5 md:mt-0">
                              <div className="grid grid-cols-6 gap-6">
                                <div className="col-span-6 sm:col-span-3">
                                  <label
                                    htmlFor="name"
                                    className="block text-base text-gray-700"
                                  >
                                    Nome <span className="mandatory">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    autoComplete="name"
                                    onChange={(e) => {
                                      handleChange(e, "name");
                                    }}
                                    value={info?.name}
                                    className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-base ${error?.name && "border-red-600"
                                      }`}
                                  />
                                  {error?.name && (
                                    <span className="text-xs text-red-600 capitalize">
                                      {error?.name}
                                    </span>
                                  )}
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                  <label
                                    htmlFor="email"
                                    className="block text-base text-gray-700"
                                  >
                                    E-mail <span className="mandatory">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    name="email"
                                    id="email"
                                    autoComplete="email"
                                    onChange={(e) => {
                                      handleChange(e, "email");
                                    }}
                                    value={info?.email}
                                    className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-base ${error?.email && "border-red-600"
                                      }`}
                                  />
                                  {error?.email && (
                                    <span className="text-xs text-red-600 capitalize">
                                      {error?.email}
                                    </span>
                                  )}
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                  <label
                                    htmlFor="name"
                                    className="block text-base text-gray-700"
                                  >
                                    Número de telefone{" "}
                                    <span className="mandatory">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    name="phone_number"
                                    id="phone_number"
                                    autoComplete="phone_number"
                                    onChange={(e) => {
                                      handleChange(e, "phone_number");
                                    }}
                                    value={info?.phone_number}
                                    className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-base ${error?.phone_number && "border-red-600"
                                      }`}
                                  />
                                  {error?.phone_number && (
                                    <span className="text-xs text-red-600 capitalize">
                                      {error?.phone_number}
                                    </span>
                                  )}
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                  <label
                                    htmlFor="status"
                                    className="block text-base text-gray-700"
                                  >
                                    Papel <span className="mandatory">*</span>
                                  </label>
                                  <select
                                    id="role_id"
                                    name="role_id"
                                    autoComplete="role_id"
                                    onChange={(e) => {
                                      handleChange(e, "role_id");
                                    }}
                                    value={info?.role_id}
                                    className={`mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-base ${error?.role_id && "border-red-600"
                                      }`}
                                  >
                                    <option>---Selecione Papel---</option>
                                    {roleList &&
                                      roleList.map((singleRole) => {
                                        return (
                                          <option value={singleRole.id}>
                                            {singleRole.name}
                                          </option>
                                        );
                                      })}
                                  </select>
                                  {error?.role_id && (
                                    <span className="text-xs text-red-600 capitalize">
                                      {error?.role_id}
                                    </span>
                                  )}
                                </div>
                                <div className="col-span-6 sm:col-span-3">
                                  <label
                                    htmlFor="last-name"
                                    className="block text-base text-gray-700"
                                  >
                                    Foto de perfil
                                  </label>
                                  <div className="mt-1 flex items-center">
                                    {info?.profile && info?.profile !== "" ? (
                                      <img
                                        className="inline-block h-12 w-12 rounded-full"
                                        src={
                                          typeof info?.profile === "object"
                                            ? URL.createObjectURL(info?.profile)
                                            : `${config.USER_MODULE_PATH + "user/"+
                                            id +
                                            "/" +
                                            info?.profile
                                            }`
                                        }
                                        onError={(e) =>
                                        (e.target.src =
                                          "/img/defaultAvatar.svg")
                                        }
                                        alt=""
                                      />
                                    ) : (
                                      <img
                                        className="inline-block h-12 w-12 rounded-full"
                                        src={"/img/defaultAvatar.svg"}
                                        alt=""
                                      />
                                    )}

                                    <div className="ml-4 flex">
                                      <div className="relative flex cursor-pointer items-center rounded-md border border-blue-gray-300 bg-white py-2 px-3 shadow-sm focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-500 focus-within:ring-offset-2 focus-within:ring-offset-blue-gray-50 hover:bg-blue-gray-50">
                                        <label
                                          htmlFor="user-photo"
                                          className="pointer-events-none relative text-base text-blue-gray-900"
                                        >
                                          <span>Alterar</span>
                                          <span className="sr-only">
                                            {" "}
                                            user photo
                                          </span>
                                        </label>
                                        <input
                                          id="user-photo"
                                          name="user-photo"
                                          type="file"
                                          onChange={(e) => {
                                            if (
                                              typeof info?.profile ===
                                              "string" &&
                                              info?.profile !== ""
                                            ) {
                                              setRemovedImage(info?.profile);
                                            }
                                            setInfo({
                                              ...info,
                                              profile: e?.target?.files[0],
                                            });
                                          }}
                                          className="absolute inset-0 h-full w-full cursor-pointer rounded-md border-gray-300 opacity-0"
                                        />
                                      </div>
                                      <button
                                        type="button"
                                        onClick={handleProfileRemove}
                                        className="ml-3 rounded-md border border-transparent bg-transparent py-2 px-3 text-base text-blue-gray-900 hover:text-blue-gray-700 focus:border-blue-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-blue-gray-50"
                                      >
                                        Remover
                                      </button>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                  <label
                                    htmlFor="status"
                                    className="block text-base text-gray-700"
                                  >
                                    Área <span className="mandatory">*</span>
                                  </label>
                                  <select
                                    id="area_id"
                                    name="area_id"
                                    autoComplete="area_id"
                                    onChange={(e) => {
                                      handleChange(e, "area_id");
                                    }}
                                    value={info?.area_id}
                                    className={`mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-base ${error?.area_id && "border-red-600"
                                      }`}
                                  >
                                    <option>---Selecione Área---</option>
                                    {areaList &&
                                      areaList.map((singleRole) => {
                                        return (
                                          <option value={singleRole.id}>
                                            {singleRole.name}
                                          </option>
                                        );
                                      })}
                                  </select>
                                  {error?.area_id && (
                                    <span className="text-xs text-red-600 capitalize">
                                      {error?.area_id}
                                    </span>
                                  )}
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                  <label
                                    htmlFor="status"
                                    className="block text-base text-gray-700"
                                  >
                                    Estado <span className="mandatory">*</span>
                                  </label>
                                  <select
                                    id="status"
                                    name="status"
                                    autoComplete="status"
                                    onChange={(e) => {
                                      handleChange(e, "status");
                                    }}
                                    value={info?.status}
                                    className={`mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-base ${error?.status && "border-red-600"
                                      }`}
                                  >
                                    <option>---Selecione Estado---</option>
                                    <option value="Active">Activo</option>
                                    <option value="Inactive">Inactivo</option>
                                  </select>
                                  {error?.status && (
                                    <span className="text-xs text-red-600 capitalize">
                                      {error?.status}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="flex justify-end">
                          <BtnRedirect
                            path={"/users/ViewUsers"}
                            title={"Cancelar"}
                          />
                          <BtnSaveRecord
                            path={"/master/users"}
                            title={"Submeter"}
                            handleSubmit={handleSubmit}
                          />
                        </div>
                      </form>
                    </Tab.Panel>
                    <Tab.Panel className="mt-6">
                      <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
                        <div className="md:grid md:gap-6">
                          <div className="mt-5 md:mt-0">
                            <div className="grid grid-cols-6 gap-6">
                              <div className="col-span-6 sm:col-span-3">
                                <label
                                  htmlFor="password"
                                  className="block text-base text-gray-700"
                                >
                                  Senha
                                </label>
                                <input
                                  type="password"
                                  name="password"
                                  id="password"
                                  onChange={(e) => {
                                    handleChange(e, "password");
                                  }}
                                  value={info?.password}
                                  className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-base ${error?.password && "border-red-600"
                                    }`}
                                />
                                {error?.password && (
                                  <span className="text-xs text-red-600 capitalize">
                                    {error?.password}
                                  </span>
                                )}
                              </div>

                              <div className="col-span-6 sm:col-span-3">
                                <label
                                  htmlFor="cpassword"
                                  className="block text-base text-gray-700"
                                >
                                  Confirme sua senha
                                </label>
                                <input
                                  type="password"
                                  name="cpassword"
                                  id="cpassword"
                                  autoComplete="cpassword"
                                  onChange={(e) => {
                                    handleChange(e, "cpassword");
                                  }}
                                  value={info?.cpassword}
                                  className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-base ${error?.cpassword && "border-red-600"
                                    }`}
                                />
                                {error?.cpassword && (
                                  <span className="text-xs text-red-600 capitalize">
                                    {error?.cpassword}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="flex justify-end mt-5">
                        <BtnRedirect
                          path={"/users/ViewUsers"}
                          title={"Cancelar"}
                        />
                        <BtnSaveRecord
                          path={"/master/users"}
                          title={"Submeter"}
                          handleSubmit={handleSubmitPwd}
                        />
                      </div>
                    </Tab.Panel>
                  </Tab.Panels>
                </Tab.Group>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
