import { Fragment, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import LeftNavigation from "../../components/LeftNavigation/leftNavigation.js";
import Header from "../../components/Header/Header";
import {
  DocumentTextIcon,
  ClockIcon,
  CogIcon,
  CreditCardIcon,
  DocumentChartBarIcon,
  HomeIcon,
  QuestionMarkCircleIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  XMarkIcon,
  BanknotesIcon,
  CalculatorIcon,
  UserIcon,
  KeyIcon,
  UsersIcon,
  UserCircleIcon,
  AcademicCapIcon,
  ClipboardDocumentIcon,
  CalendarIcon,
  MapIcon,
  AdjustmentsVerticalIcon,
  Square3Stack3DIcon,
} from "@heroicons/react/24/outline";
import Cookies from "js-cookie";

const navigation = [
  { name: "Home", href: "#", icon: HomeIcon, current: true },
  { name: "History", href: "#", icon: ClockIcon, current: false },
  { name: "Balances", href: "#", icon: ScaleIcon, current: false },
  { name: "Cards", href: "#", icon: CreditCardIcon, current: false },
  { name: "Recipients", href: "#", icon: UserGroupIcon, current: false },
  { name: "Reports", href: "#", icon: DocumentChartBarIcon, current: false },
];
const secondaryNavigation = [
  { name: "Settings", href: "#", icon: CogIcon },
  { name: "Help", href: "#", icon: QuestionMarkCircleIcon },
  { name: "Privacy", href: "#", icon: ShieldCheckIcon },
];
const cards = [
  { name: "Total Customers", href: "#", icon: ScaleIcon, amount: "30,659" },
];
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Dashboard() {
  let history = useHistory();
  const [sidebarOpen, setSidebarOpen] = useState(false);



  return (
    <>
      <div className="min-h-full">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-skin-secondary pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-shrink-0 items-center px-4">
                    <img
                      className="h-8 w-auto"
                      src="https://tailwindui.com/img/logos/mark.svg?color=cyan&shade=300"
                      alt="Easywire logo"
                    />
                  </div>
                  <nav
                    className="mt-5 h-full flex-shrink-0 divide-y divide-cyan-800 overflow-y-auto"
                    aria-label="Sidebar"
                  >
                    <div className="space-y-1 px-2">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "bg-skin-primary text-white"
                              : "text-white hover:text-white left-menu-btn",
                            "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          <item.icon
                            className="mr-4 h-6 w-6 flex-shrink-0 text-white"
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      ))}
                    </div>
                    <div className="mt-6 pt-6">
                      <div className="space-y-1 px-2">
                        {secondaryNavigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              item.current
                                ? "bg-skin-primary text-white"
                                : "text-white hover:text-white left-menu-btn",
                              "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                            )}
                          >
                            <item.icon
                              className="mr-4 h-6 w-6 text-white"
                              aria-hidden="true"
                            />
                            {item.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  </nav>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <LeftNavigation />
        </div>

        <div className="flex flex-1 flex-col lg:pl-64">
          <Header />
          <main className="flex-1">
            <div className="mt-8 page_bg bg_borderradius pb-12">
              <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8">
              <div className="py-5 flex items-center justify-between">
									<h1 className="text-3xl font-medium tracking-tight dark:text-[#fff] text-black">
                  Setting
									</h1>
								</div>
                <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-1 lg:grid-cols-1">
                  {cards.map((card) => (
                    <div key={card.name} className="overflow-hidden p-1.5">
                      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                colSpan={4}
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-lg font-medium text-gray-900 sm:pl-6"
                              >
                                Configuração
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white">
                            <tr className={"bg-white"}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base text-gray-900 sm:pl-6">
                                <Link
                                  to="/master/country"
                                  className="flex items-center hover:text-red-600"
                                >
                                  <img
                                    className="h-6 w-6 text-red-600 mr-2"
                                    src="img/country.svg"
                                    alt="Transfer Category"
                                  />
                                  País
                                </Link>
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base text-gray-900 sm:pl-6">
                                <Link
                                  to="/master/paymentMode"
                                  className="flex items-center hover:text-red-600"
                                >
                                  <BanknotesIcon
                                    className="h-6 w-6 text-red-600 mr-2"
                                    aria-hidden="true"
                                  />
                                  Formas de pagamento
                                </Link>
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base text-gray-900 sm:pl-6">
                                <Link
                                  to="/master/supplier-type"
                                  className="flex items-center hover:text-red-600"
                                >
                                  <img
                                    className="h-6 w-6 text-red-600 mr-2"
                                    src="img/supplier.svg"
                                    alt="Transfer Category"
                                  />
                                  Tipo de fornecedor
                                </Link>
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base text-gray-900 sm:pl-6">
                                <Link
                                  to="/master/service_category"
                                  className="flex items-center hover:text-red-600"
                                >
                                  <img
                                    className="h-6 w-6 text-red-600 mr-2"
                                    src="img/service.svg"
                                    alt="Transfer Category"
                                  />
                                  Categoria do serviço
                                </Link>
                              </td>
                            </tr>
                            <tr className={"bg-gray-50"}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base text-gray-900 sm:pl-6">
                                <Link
                                  to="/master/bank"
                                  className="flex items-center hover:text-red-600"
                                >
                                  <img
                                    className="h-6 w-6 text-red-600 mr-2"
                                    src="img/bank.svg"
                                    alt="Transfer Category"
                                  />
                                  Banco
                                </Link>
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base text-gray-900 sm:pl-6">
                                <Link
                                  to="/master/department"
                                  className="flex items-center hover:text-red-600"
                                >
                                  <UserGroupIcon
                                    className="h-6 w-6 text-red-600 mr-2"
                                    aria-hidden="true"
                                  />
                                  Área
                                </Link>
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base text-gray-900 sm:pl-6">
                                <Link
                                  to="/master/recipient"
                                  className="flex items-center hover:text-red-600"
                                >
                                  <img
                                    className="h-7 w-7 text-red-600 mr-2"
                                    src="img/recipient.svg"
                                    alt="Transfer Category"
                                  />
                                  Destinatario
                                </Link>
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base text-gray-900 sm:pl-6">
                                <Link
                                  to="/master/budget_map"
                                  className="flex items-center hover:text-red-600"
                                >
                                  <CalculatorIcon
                                    className="h-6 w-6 text-red-600 mr-2"
                                    aria-hidden="true"
                                  />
                                  Mapa de Intenção Contratual
                                </Link>
                              </td>
                            </tr>
                            <tr className={"bg-white"}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base text-gray-900 sm:pl-6">
                                <Link
                                  to="/master/procedure_type"
                                  className="flex items-center hover:text-red-600"
                                >
                                  <img
                                    className="h-6 w-6 text-red-600 mr-2"
                                    src="img/procedure.svg"
                                    alt="Transfer Category"
                                  />
                                  Tipo de procedimento
                                </Link>
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base text-gray-900 sm:pl-6">
                                <Link
                                  to="/master/role"
                                  className="flex items-center hover:text-red-600"
                                >
                                  <UserIcon
                                    className="h-6 w-6 text-red-600 mr-2"
                                    aria-hidden="true"
                                  />
                                  Papel
                                </Link>
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base text-gray-900 sm:pl-6">
                                <Link
                                  to="/master/contractDocuments"
                                  className="flex items-center hover:text-red-600"
                                >
                                  <DocumentTextIcon
                                    className="h-6 w-6 text-red-600 mr-2"
                                    aria-hidden="true"
                                  />
                                  Peças do Procedimento
                                </Link>
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base text-gray-900 sm:pl-6">
                                <Link
                                  to=""
                                  className="flex items-center hover:text-red-600"
                                >
                                  <KeyIcon
                                    className="h-6 w-6 text-red-600 mr-2"
                                    aria-hidden="true"
                                  />
                                  Permissões
                                </Link>
                              </td>
                            </tr>
                            <tr className={"bg-gray-50"}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base text-gray-900 sm:pl-6">
                                <Link
                                  to="/users/ViewUsers"
                                  className="flex items-center hover:text-red-600"
                                >
                                  <UsersIcon
                                    className="h-6 w-6 text-red-600 mr-2"
                                    aria-hidden="true"
                                  />
                                  Utilizadores
                                </Link>
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base text-gray-900 sm:pl-6">
                                <Link
                                  to="/master/employee"
                                  className="flex items-center hover:text-red-600"
                                >
                                  <UserCircleIcon
                                    className="h-6 w-6 text-red-600 mr-2"
                                    aria-hidden="true"
                                  />
                                  Colaborador
                                </Link>
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base text-gray-900 sm:pl-6">
                                <Link
                                  to=""
                                  className="flex items-center hover:text-red-600"
                                >
                                  <ClockIcon
                                    className="h-6 w-6 text-red-600 mr-2"
                                    aria-hidden="true"
                                  />
                                  Histórico de Acessos
                                </Link>
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base text-gray-900 sm:pl-6">
                                <Link
                                  to="/master/qualification_criteria"
                                  className="flex items-center hover:text-red-600"
                                >
                                  <AcademicCapIcon
                                    className="h-6 w-6 text-red-600 mr-2"
                                    aria-hidden="true"
                                  />
                                  Critério de Qualificação
                                </Link>
                              </td>
                            </tr>
                            {/* <tr className={'bg-white'}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                <Link to='' className='flex items-center hover:text-red-600'>
                                  <BanknotesIcon className="h-6 w-6 text-red-600 mr-2" aria-hidden="true" />
                                  Formas de pagamento
                                </Link>
                              </td>
                            </tr> */}
                            {/* <tr className={'bg-gray-50'}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                <Link to='' className='flex items-center hover:text-red-600'>
                                  <img
                                    className="h-6 w-6 text-red-600 mr-2"
                                    src="img/service.svg"
                                    alt="Transfer Category"
                                  />
                                  Categoria do serviço
                                </Link>
                              </td>
                            </tr> */}
                            {/* <tr className={'bg-white'}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                <Link to='' className='flex items-center hover:text-red-600'>
                                  <UserGroupIcon className="h-6 w-6 text-red-600 mr-2" aria-hidden="true" />
                                  Área
                                </Link>
                              </td>
                            </tr> */}
                            {/* <tr className={'bg-gray-50'}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                <Link to='' className='flex items-center hover:text-red-600'>
                                  <CalculatorIcon className="h-6 w-6 text-red-600 mr-2" aria-hidden="true" />
                                  Mapa de Intenção Contratual
                                </Link>
                              </td>
                            </tr> */}
                            {/* <tr className={'bg-white'}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                <Link to='' className='flex items-center hover:text-red-600'>
                                  <UserIcon className="h-6 w-6 text-red-600 mr-2" aria-hidden="true" />
                                  Papel
                                </Link>
                              </td>
                            </tr> */}
                            {/* <tr className={'bg-gray-50'}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                <Link to='' className='flex items-center hover:text-red-600'>
                                  <KeyIcon className="h-6 w-6 text-red-600 mr-2" aria-hidden="true" />
                                  Permissões
                                </Link>
                              </td>
                            </tr> */}
                            <tr className={"bg-white"}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base text-gray-900 sm:pl-6">
                                <Link
                                  to="/master/internal_communication"
                                  className="flex items-center hover:text-red-600"
                                >
                                  <ClipboardDocumentIcon
                                    className="h-6 w-6 text-red-600 mr-2"
                                    aria-hidden="true"
                                  />
                                  Suporte CI
                                </Link>
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base text-gray-900 sm:pl-6">
                                <Link
                                  to="/master/budgetExchange"
                                  className="flex items-center hover:text-red-600"
                                >
                                  <UserCircleIcon
                                    className="h-6 w-6 text-red-600 mr-2"
                                    aria-hidden="true"
                                  />
                                  Câmbio orçamental
                                </Link>{" "}
                                &nbsp;
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base text-gray-900 sm:pl-6">
                                <Link
                                  to="/master/evaluation-committee-role/view"
                                  className="flex items-center hover:text-red-600"
                                >
                                  <ClipboardDocumentIcon
                                    className="h-6 w-6 text-red-600 mr-2"
                                    aria-hidden="true"
                                  />
                                  Função Comissão de Avaliação
                                </Link>
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base text-gray-900 sm:pl-6">
                                <Link
                                  to="/master/budget-year"
                                  className="flex items-center hover:text-red-600"
                                >
                                  <CalendarIcon
                                    className="h-6 w-6 text-red-600 mr-2"
                                    aria-hidden="true"
                                  />
                                  Ano Orçamental
                                </Link>
                              </td>
                            </tr>
                            <tr className={"bg-gray-50"}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base text-gray-900 sm:pl-6">
                                <Link
                                  to="master/currency"
                                  className="flex items-center hover:text-red-600"
                                >
                                  <UserCircleIcon
                                    className="h-6 w-6 text-red-600 mr-2"
                                    aria-hidden="true"
                                  />
                                  Moeda
                                </Link>{" "}
                                &nbsp;
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base text-gray-900 sm:pl-6">
                                <Link
                                  to="master/object_tender"
                                  className="flex items-center hover:text-red-600"
                                >
                                  <UserCircleIcon
                                    className="h-6 w-6 text-red-600 mr-2"
                                    aria-hidden="true"
                                  />
                                  Objeto do Concurso
                                </Link>{" "}
                                &nbsp;
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base text-gray-900 sm:pl-6">
                                <Link
                                  to="master/evaluation_criteria"
                                  className="flex items-center hover:text-red-600"
                                >
                                  <MapIcon
                                    className="h-6 w-6 text-red-600 mr-2"
                                    aria-hidden="true"
                                  />
                                  Critério de Avaliação
                                </Link>{" "}
                                &nbsp;
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base text-gray-900 sm:pl-6">
                                <Link
                                  to="master/attributes"
                                  className="flex items-center hover:text-red-600"
                                >
                                  <AdjustmentsVerticalIcon
                                    className="h-6 w-6 text-red-600 mr-2"
                                    aria-hidden="true"
                                  />
                                  Atributos
                                </Link>{" "}
                                &nbsp;
                              </td>
                            </tr>
                            <tr className={"bg-white"}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base text-gray-900 sm:pl-6">
                                <Link
                                  to="master/concurringRequirements"
                                  className="flex items-center hover:text-red-600"
                                >
                                  <Square3Stack3DIcon
                                    className="h-6 w-6 text-red-600 mr-2"
                                    aria-hidden="true"
                                  />
                                  Requisitos do Concorrente
                                </Link>{" "}
                                &nbsp;
                              </td>

                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base text-gray-900 sm:pl-6">
                                <Link
                                  to="/master/qualification-document/view"
                                  className="flex items-center hover:text-red-600"
                                >
                                  <Square3Stack3DIcon
                                    className="h-6 w-6 text-red-600 mr-2"
                                    aria-hidden="true"
                                  />
                                  Documentos de Qualificação
                                </Link>{" "}
                                &nbsp;
                              </td>

                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base text-gray-900 sm:pl-6">
                                <Link
                                  to="/procedure_to_evaluation/add"
                                  className="flex items-center hover:text-red-600"
                                >
                                  <Square3Stack3DIcon
                                    className="h-6 w-6 text-red-600 mr-2"
                                    aria-hidden="true"
                                  />
                                  Avaliação de procedimentos
                                </Link>{" "}
                                &nbsp;
                              </td>

                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base text-gray-900 sm:pl-6">
                                <Link
                                  to="/category-administrator"
                                  className="flex items-center hover:text-red-600"
                                >
                                  <Square3Stack3DIcon
                                    className="h-6 w-6 text-red-600 mr-2"
                                    aria-hidden="true"
                                  />
                                  Categoria do Administrador
                                </Link>{" "}
                                &nbsp;
                              </td>
                            </tr>

                            <tr className={"bg-white"}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base text-gray-900 sm:pl-6">
                                <Link
                                  to="/administrator"
                                  className="flex items-center hover:text-red-600"
                                >
                                  <Square3Stack3DIcon
                                    className="h-6 w-6 text-red-600 mr-2"
                                    aria-hidden="true"
                                  />
                                  Administrador
                                </Link>{" "}
                                &nbsp;
                              </td>

                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base text-gray-900 sm:pl-6">
                                <Link
                                  to="/accessrights"
                                  className="flex items-center hover:text-red-600"
                                >
                                  <KeyIcon
                                    className="h-6 w-6 text-red-600 mr-2"
                                    aria-hidden="true"
                                  />
                                  Privilégios da Comissão de Avaliação
                                </Link>{" "}
                                &nbsp;
                              </td>

                            </tr>

                          </tbody>
                        </table>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
