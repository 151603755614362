import React from "react";

// For Login User Layout
function Template({ children }) {
  return (
    <div className="min-h-screen inner-pagebg">
      {/* <Header /> */}
      {children}
      {/* <Footer /> */}
    </div>
  );
}
export default Template;
