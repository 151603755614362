import { React, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { validateForm } from "../../../src/utils/common";
import { toast } from "react-toastify";
import config from "../../../src/utils/config";
import Cookies from "js-cookie";
export default function Example() {
  let history = useHistory();
  const [fields, setFields] = useState();
  const [error, setError] = useState();
  const OPTIONS = {
    CONTRACT: process.env.REACT_APP_CONTRACT_LINK,
    AREA_MANAGER: process.env.REACT_APP_AREA_MANAGER_LINK,
    CONCURRING: process.env.REACT_APP_CONCURRING_LINK,
    EVCOMMITEE: process.env.REACT_APP_EVCOMMITEE_LINK
  };

  function handleChange(field, e) {
    setFields({ ...fields, [field]: e.target.value });
  }

  const login = async () => {
    try {
      const includedFields = ["email", "password"];
      const validationResponse = validateForm(fields, includedFields);
      setError(validationResponse?.errors);
      if (validationResponse?.isValid) {
        const loginResponse = await config.postAPI({
          url: "auth/login",
          params: fields,
        });

        if (loginResponse.error) {
          return toast.error(loginResponse.error.message);
        } else {
          Cookies.set("G-accessToken", loginResponse?.payload?.token, { domain: process.env.REACT_APP_DOMAIN_NAME });
          Cookies.set("dataKey", JSON.stringify(loginResponse?.payload), { domain: process.env.REACT_APP_DOMAIN_NAME });
          Cookies.set("area", loginResponse?.payload?.area, { domain: process.env.REACT_APP_DOMAIN_NAME });
          Cookies.set("areaID", loginResponse?.payload.area_id, { domain: process.env.REACT_APP_DOMAIN_NAME });
          Cookies.set("user_id", loginResponse?.payload?.uId, { domain: process.env.REACT_APP_DOMAIN_NAME });
          Cookies.set("role", loginResponse?.payload?.roleCode, { domain: process.env.REACT_APP_DOMAIN_NAME });
          Cookies.set("colorTheme", loginResponse?.payload?.color_theme, { domain: process.env.REACT_APP_DOMAIN_NAME });
          if (OPTIONS?.[loginResponse?.payload?.roleCode]) {
            window.location.replace(OPTIONS?.[loginResponse?.payload?.roleCode] + "/dashboard");
          } else {
            // history.push("/setting");
            history.push("/activities/activityview");
          }
        }
      }
    } catch (error) {
      return toast.error(error?.message);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      login()
    }
  };

  return (
    <>
      <div className="h-screen">
        <div className="lg:grid grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 px-8 lg:items-center h-screen">
          <div className="mb-8 lg:mb-0">
            <div className="pb-0 lg:pb-8 pt-8 lg:pt-0">
              <img
                className="mx-auto"
                src="img/SNCP.jpg"
                alt="Your Company"
              />     
            </div>            
            {/* <div className="mt-12 hidden lg:block">
            <img
                className="mx-auto"
                src="img/signiin-pic.png"
                alt="siginin"
              />     
            </div> */}
          </div>
          <div className="bg-white rounded-3xl p-10 signin_box shadow lg:flex lg:items-center">
            <div className="w-full">
              <div className="text-3xl lg:text-4xl">Bem-vindo</div>
              <p className="pt-2 gray_prgtext font-medium text-base">Por favor insira as suas credenciais.</p>
              <form className="space-y-6 pt-6 lg:pt-14" action="#" method="POST">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-base font-medium text-gray-700"
                  >
                    E-mail
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-3 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      onChange={(e) => handleChange("email", e)}
                      onKeyDown={(e) => handleKeyDown(e)}
                    />
                    {error?.email && (
                      <span className="text-xs text-red-600 capitalize">
                        {error?.email}
                      </span>
                    )}
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="block text-base font-medium text-gray-700"
                  >
                    Senha
                  </label>
                  <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-3 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      onChange={(e) => handleChange("password", e)}
                      onKeyDown={(e) => handleKeyDown(e)}
                    />
                    {error?.password && (
                      <span className="text-xs text-red-600 capitalize">
                        {error?.password}
                      </span>
                    )}
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                    <label
                      htmlFor="remember-me"
                      className="ml-2 block text-base text-gray-900"
                    >
                      Lembra-me
                    </label>
                  </div>

                  <div className="text-base">
                    <Link
                      to="/forgot-password"
                      className="font-medium link text-skin-primary"
                    >
                      {" "}
                      Esqueci a senha
                    </Link>
                  </div>
                </div>

                <div>
                  {/* <Link to="/setting"> */}
                  <button
                    type="button"
                    className="flex w-full justify-center bg-skin-login text-lg rounded-md border border-transparent py-3 px-4 font-medium text-white shadow-sm focus:outline-none slideleft"
                    onClick={() => login()}
                  >
                    Entrar
                  </button>
                  {/* </Link> */}
                </div>
              </form>
            </div>          
          
          </div>
        </div>
        {/* <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img
              className="mx-auto h-12 w-auto"
              src="img/logo-front.png"
              alt="Your Company"
            />           
          </div>
          
        </div> */}
      </div>
    </>
  );
}
