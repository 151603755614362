import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import LeftNavigation from "../../../components/LeftNavigation/leftNavigation.js";
import BtnRedirect from "../../../components/Form/Button/BtnRedirect";
import BtnSaveRecord from "../../../components/Form/Button/BtnSaveRecord";
import Header from "../../../components/Header/Header";
import config from "../../../utils/config";
import { toast } from "react-toastify";
import { validateForm } from "../../../utils/common";
import {
  ClockIcon,
  CogIcon,
  CreditCardIcon,
  DocumentChartBarIcon,
  HomeIcon,
  QuestionMarkCircleIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

const navigation = [
  { name: "Home", href: "#", icon: HomeIcon, current: true },
  { name: "History", href: "#", icon: ClockIcon, current: false },
  { name: "Balances", href: "#", icon: ScaleIcon, current: false },
  { name: "Cards", href: "#", icon: CreditCardIcon, current: false },
  { name: "Recipients", href: "#", icon: UserGroupIcon, current: false },
  { name: "Reports", href: "#", icon: DocumentChartBarIcon, current: false },
];
const secondaryNavigation = [
  { name: "Settings", href: "#", icon: CogIcon },
  { name: "Help", href: "#", icon: QuestionMarkCircleIcon },
  { name: "Privacy", href: "#", icon: ShieldCheckIcon },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function Roles({ history }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [info, setInfo] = useState({ name: "", status: "" });
  const [error, setError] = useState({});

  const handleChange = (e, name) => {
    const setData = {
      ...info,
      [name]: e?.target?.value,
    };
    setInfo(setData);
  };

  const handleSubmit = async () => {
    try {
      const includedFields = ["name", "status"];
      const validationResponse = validateForm(info, includedFields);
      setError(validationResponse?.errors);
      if (validationResponse?.isValid) {
        const insertResponse = await config.postAPI({
          url: "categoryAdministrator/create",
          params: info,
        });
        if (insertResponse?.response?.data?.error?.message) {
          return toast.error(insertResponse?.response?.data?.error?.message);
        }
        setTimeout(() => {
          return toast.success("Registo criado com sucesso");
        }, 200);
        history.push("/category-administrator");
      }
    } catch (error) {
      return toast.error(error?.message);
    }
  };

  return (
    <>
      <div className="min-h-full">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-skin-secondary pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-shrink-0 items-center px-4">
                    <img
                      className="h-8 w-auto"
                      src="https://tailwindui.com/img/logos/mark.svg?color=cyan&shade=300"
                      alt="Easywire logo"
                    />
                  </div>
                  <nav
                    className="mt-5 h-full flex-shrink-0 divide-y divide-cyan-800 overflow-y-auto"
                    aria-label="Sidebar"
                  >
                    <div className="space-y-1 px-2">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "bg-skin-primary text-white"
                              : "text-white hover:text-white left-menu-btn",
                            "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          <item.icon
                            className="mr-4 h-6 w-6 flex-shrink-0 text-white"
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      ))}
                    </div>
                    <div className="mt-6 pt-6">
                      <div className="space-y-1 px-2">
                        {secondaryNavigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              item.current
                                ? "bg-skin-primary text-white"
                                : "text-white hover:text-white left-menu-btn",
                              "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                            )}
                          >
                            <item.icon
                              className="mr-4 h-6 w-6 text-white"
                              aria-hidden="true"
                            />
                            {item.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  </nav>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
          <LeftNavigation />
        </div>

        <div className="flex flex-1 flex-col lg:pl-64">
          <Header />
          <main className="flex-1">
            <div className="mt-8 page_bg bg_borderradius pb-12">
              <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8">
                <div className="py-5 flex items-center justify-between">
                  <h1 className="text-3xl font-medium tracking-tight dark:text-[#fff] text-black">
                    Novo Categoria do Administrador
                  </h1>
                </div>
                <form className="space-y-6" action="#" method="POST">
                  <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
                    <div className="md:grid md:gap-6">
                      <div className="mt-5 md:mt-0">
                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="name"
                              className="block text-base text-gray-700"
                            >
                              Nome <span className="mandatory">*</span>
                            </label>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              autoComplete="name"
                              onChange={(e) => {
                                handleChange(e, "name");
                              }}
                              value={info?.name}
                              className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-base ${error?.name && "border-red-600"
                                }`}
                            />
                            {error?.name && (
                              <span className="text-xs text-red-600 capitalize">
                                {error?.name}
                              </span>
                            )}
                          </div>
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="status"
                              className="block text-base text-gray-700"
                            >
                              Estado <span className="mandatory">*</span>
                            </label>
                            <select
                              id="status"
                              name="status"
                              autoComplete="status"
                              onChange={(e) => {
                                handleChange(e, "status");
                              }}
                              value={info?.status}
                              className={`mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-base ${error?.status && "border-red-600"
                                }`}
                            >
                              <option>---Selecione Estado---</option>
                              <option value="Active">Activo</option>
                              <option value="Inactive">Inactivo</option>
                            </select>
                            {error?.status && (
                              <span className="text-xs text-red-600 capitalize">
                                {error?.status}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-end">
                    <BtnRedirect
                      path={"/category-administrator"}
                      title={"Cancelar"}
                    />
                    <BtnSaveRecord
                      path={"/category-administrator"}
                      title={"Submeter"}
                      handleSubmit={handleSubmit}
                    />
                  </div>
                </form>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}