import { Disclosure } from "@headlessui/react";
import {
  SquaresPlusIcon,
  CogIcon,
  QuestionMarkCircleIcon,
  ShieldCheckIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function LeftNavigation() {
  const navigation = [
    { name: "Dashboard", href: "/activities/activityview", icon: SquaresPlusIcon, current: true },
    // {
    //   name: "Novo Contrato",
    //   href: "/new-contract/NewContract",
    //   icon: DocumentTextIcon,
    //   current: false,
    //   showIcon: true,
    // },
    // {
    //   name: "Fornecedor",
    //   icon: TruckIcon,
    //   //path: "../img/plane.svg",
    //   showIcon: true,
    //   current: false,
    //   children: [
    //     { name: "Novo", href: "/provider/NewProvider" },
    //     { name: "Fornecedores", href: "/provider/ViewProvider" },
    //   ],
    // },
    // {
    //   name: "Solicitações",
    //   icon: ClipboardDocumentListIcon,
    //   //path: "../img/plane.svg",
    //   showIcon: true,
    //   current: false,
    //   children: [
    //     { name: "Novo", href: "/requests/NewRequest" },
    //     { name: "Solicitações recebidas", href: "/requests/RequestsReceived" },
    //     { name: "CI - PCA", href: "/requests/RequestsPCA" },
    //     { name: "CI - TPE", href: "/requests/RequestsTPE" },
    //   ],
    // },
    // {
    //   name: "Concurso",
    //   icon: Cog8ToothIcon,
    //   //path: "../img/plane.svg",
    //   showIcon: true,
    //   current: false,
    //   children: [
    //     { name: "Novo", href: "/contest/NewContest" },
    //     { name: "Concursos", href: "/contest/ViewContests" },
    //   ],
    // },
    // {
    //   name: "Comissão de Avaliação",
    //   icon: UserGroupIcon,
    //   //path: "../img/plane.svg",
    //   showIcon: true,
    //   current: false,
    //   children: [
    //     { name: "Novo", href: "/evaluation-committee/NewEvaluationCommittee" },
    //     {
    //       name: "Comissões de avaliação",
    //       href: "/evaluation-committee/SeeEvaluationCommittee",
    //     },
    //   ],
    // },
    // {
    //   name: "Concorrentes",
    //   href: "/competitors/ViewCompetitors",
    //   icon: BuildingOfficeIcon,
    //   current: false,
    //   showIcon: true,
    // },
  ];

  const secondaryNavigation = [
    { name: "Settings", href: "#", icon: CogIcon },
    { name: "Help", href: "#", icon: QuestionMarkCircleIcon },
    { name: "Privacy", href: "#", icon: ShieldCheckIcon },
  ];

  return (
    <>
      <div className="flex flex-grow flex-col overflow-y-auto bg-skin-secondary pt-5 pb-4">
        <div className="flex flex-shrink-0 items-center px-4">
          <img
            className="h-22 w-auto"
            src="/img/SNCP.jpg"
            alt="Your Company"
          />
        </div>
        <div className="mt-5 flex flex-grow flex-col">
          <nav className="flex-1 space-y-1 px-2" aria-label="Sidebar">
            {navigation.map((item, index) =>
              !item.children ? (
                <Link to={item.href} key={index}>
                  <div
                    className={classNames(
                      item.current
                        ? "bg-skin-primary text-white"
                        : "text-white left-menu-btn hover:text-white",
                      "group w-full flex items-center pl-2 py-2 text-base font-medium rounded-md"
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? "text-black"
                          : "text-black group-hover:text-white",
                        "mr-3 flex-shrink-0 h-6 w-6"
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </div>
                </Link>
              ) : (
                <Disclosure as="div" className="space-y-1" key={index}>
                  {({ open }) => (
                    <>
                      <Disclosure.Button
                        className={classNames(
                          item.current
                            ? "bg-skin-secondary text-white"
                            : "text-white hover:bg-gray-50 hover:text-white left-menu-btn",
                          "group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md focus:outline-none"
                        )}
                      >
                        {item.showIcon ? (
                          <item.icon
                            className="mr-3 h-6 w-6 flex-shrink-0 text-white group-hover:text-white"
                            aria-hidden="true"
                          />
                        ) : (
                          <img
                            className="mr-3 h-7 w-7 flex-shrink-0 text-white group-hover:text-white"
                            src={item.path}
                          ></img>
                        )}
                        <span className="flex-1">{item.name}</span>
                        <svg
                          className={classNames(
                            open ? "text-white rotate-90" : "text-white",
                            "ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-white"
                          )}
                          viewBox="0 0 20 20"
                          aria-hidden="true"
                        >
                          <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                        </svg>
                      </Disclosure.Button>
                      <Disclosure.Panel className="space-y-1">
                        {item.children.map((subItem, key) => (
                          <Link to={subItem.href} key={key}>
                            <Disclosure.Button
                              key={subItem.name}
                              as="a"
                              className="group flex w-full items-center rounded-md py-2 pl-11 pr-2 text-sm font-medium text-white left-menu-btn hover:text-white"
                            >
                              {subItem.name}
                            </Disclosure.Button>
                          </Link>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              )
            )}
          </nav>
        </div>
        <div className="flex flex-shrink-0 p-4">
          <div className="group block w-full flex-shrink-0">
            <div className="flex items-center">
              <div>
                {/* <img
                  className="inline-block"
                  src="/img/side-bot-logo.png"
                  alt=""
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
