import React from "react";
// import { useHistory } from "react-router-dom";

// For Adding New Record
function SaveRecord({ path, title, handleSubmit }) {
  // const history = useHistory();
  return (
    <button
      type="button"
      onClick={(e) => handleSubmit(e)}
      className="btn-link ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-[16px] font-medium shadow-sm bg-skin-primary text-skin-white rounded-md buttonfx slideleft"
      // onClick={() => history.push(path)}
    >
      {title}
    </button>
  );
}

export default SaveRecord;
