import { useEffect, useState, useRef } from "react";
import LeftNavigation from "../../../components/LeftNavigation/leftNavigation.js";
import BtnRedirect from "../../../components/Form/Button/BtnRedirect";
import BtnSaveRecord from "../../../components/Form/Button/BtnSaveRecord";
import Header from "../../../components/Header/Header";
import config from "../../../utils/config";
import { toast } from "react-toastify";
import { logFormData, validateForm } from "../../../utils/common";
import {
  ClockIcon,
  CogIcon,
  CreditCardIcon,
  DocumentChartBarIcon,
  HomeIcon,
  QuestionMarkCircleIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import Cookies from "js-cookie";

const navigation = [
  { name: "Home", href: "#", icon: HomeIcon, current: true },
  { name: "History", href: "#", icon: ClockIcon, current: false },
  { name: "Balances", href: "#", icon: ScaleIcon, current: false },
  { name: "Cards", href: "#", icon: CreditCardIcon, current: false },
  { name: "Recipients", href: "#", icon: UserGroupIcon, current: false },
  { name: "Reports", href: "#", icon: DocumentChartBarIcon, current: false },
];
const secondaryNavigation = [
  { name: "Settings", href: "#", icon: CogIcon },
  { name: "Help", href: "#", icon: QuestionMarkCircleIcon },
  { name: "Privacy", href: "#", icon: ShieldCheckIcon },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Roles({ history }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [info, setInfo] = useState({ name: "", status: "" });
  const [error, setError] = useState({});
  const [roleList, setRoleList] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [areaList, setAreaList] = useState();

  function getRoleList() {
    config
      .getAPI({ url: "role/list", params: { status: "Active" } })
      .then((data) => {
        if (data.payload) {
          setRoleList(data.payload.rows);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  useEffect(() => {
    getRoleList();
  }, []);
  const [frmSrc, setFrmsrc] = useState("");
  const ref = useRef();
  const reset = () => {
    ref.current.value = "";
  };
  const handleProfileRemove = async () => {
    if (info?.profile) {
      setInfo({
        ...info,
        profile: "",
      });
    }
  };

  const handleChange = (e, name) => {
    if (name === "profile") {
      const setData = {
        ...info,
        [name]: e?.target?.files?.[0],
      };
      setInfo(setData);
    } else {
      const setData = {
        ...info,
        [name]: e?.target?.value,
      };
      setInfo(setData);
    }
  };

  const getAreaList = () => {
    config
      .getAPI({ url: "department/list", params: {} })
      .then((data) => {
        if (data.payload) {
          setAreaList(data.payload.rows);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    getAreaList();
  }, []);

  const handleSubmit = async () => {
    try {
      const includedFields = [
        "name",
        "email",
        "password",
        "cpassword",
        "phone_number",
        "role_id",
        "status",
        // "profile"
      ];

      const validationResponse = validateForm(info, includedFields);
      setError(validationResponse?.errors);
      if (validationResponse?.isValid) {
        if (typeof info.profile === "object") {
          let formData = new FormData();
          formData.append("fields", JSON.stringify(info));

          formData.append("file", info?.profile);
          logFormData(formData);
          const insertResponse = await config.postFormDataAPI({
            url: "user/create",
            params: formData,
          });
          if (insertResponse?.response?.data?.error?.message) {
            return toast.error(insertResponse?.response?.data?.error?.message);
          }
          if (
            insertResponse?.payload?.errors ||
            insertResponse?.payload?.email === "SequelizeUniqueConstraintError"
          ) {
            return toast.error("Este registo já existe");
          }
          setTimeout(() => {
            return toast.success("Registo criado com sucesso");
          }, 200);
          history.push("/users/ViewUsers");
        } else {
          const insertResponse = await config.postAPI({
            url: "user/create",
            params: info,
          });
          if (insertResponse?.response?.data?.error?.message) {
            return toast.error(insertResponse?.response?.data?.error?.message);
          }
          if (
            insertResponse?.payload?.errors ||
            insertResponse?.payload?.email === "SequelizeUniqueConstraintError"
          ) {
            return toast.error("Este registo já existe");
          }
          setTimeout(() => {
            return toast.success("Registo criado com sucesso");
          }, 200);
          history.push("/users/ViewUsers");
        }
      }
    } catch (error) {
      return toast.error(error?.message);
    }
  };

  return (
    <>
      <div className="min-h-full">
        <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
          <LeftNavigation />
        </div>

        <div className="flex flex-1 flex-col lg:pl-64">
          <Header />
          <main className="flex-1">
            <div className="mt-8 page_bg bg_borderradius pb-12">
              <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8">
                <div className="py-5 flex items-center justify-between">
                  <h1 className="text-3xl font-medium tracking-tight dark:text-[#fff] text-black">
                    Novo Utilizadores
                  </h1>
                </div>
                <form
                  className="space-y-6"
                  action="#"
                  method="POST"
                  enctype="multipart/form-data"
                >
                  <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
                    <div className="md:grid md:gap-6">
                      <div className="mt-5 md:mt-0">
                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="name"
                              className="block text-base text-gray-700"
                            >
                              Nome <span className="mandatory">*</span>
                            </label>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              autoComplete="name"
                              onChange={(e) => {
                                handleChange(e, "name");
                              }}
                              value={info?.name}
                              className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-base ${error?.name && "border-red-600"
                                }`}
                            />
                            {error?.name && (
                              <span className="text-xs text-red-600 capitalize">
                                {error?.name}
                              </span>
                            )}
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="email"
                              className="block text-base text-gray-700"
                            >
                              E-mail <span className="mandatory">*</span>
                            </label>
                            <input
                              type="text"
                              name="email"
                              id="email"
                              autoComplete="email"
                              onChange={(e) => {
                                handleChange(e, "email");
                              }}
                              value={info?.email}
                              className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-base ${error?.email && "border-red-600"
                                }`}
                            />
                            {error?.email && (
                              <span className="text-xs text-red-600 capitalize">
                                {error?.email}
                              </span>
                            )}
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="name"
                              className="block text-base text-gray-700"
                            >
                              Número de telefone{" "}
                              <span className="mandatory">*</span>
                            </label>
                            <input
                              type="text"
                              name="phone_number"
                              id="phone_number"
                              autoComplete="phone_number"
                              onChange={(e) => {
                                handleChange(e, "phone_number");
                              }}
                              value={info?.phone_number}
                              className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-base ${error?.phone_number && "border-red-600"
                                }`}
                            />
                            {error?.phone_number && (
                              <span className="text-xs text-red-600 capitalize">
                                {error?.phone_number}
                              </span>
                            )}
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="status"
                              className="block text-base text-gray-700"
                            >
                              Papel <span className="mandatory">*</span>
                            </label>
                            <select
                              id="role_id"
                              name="role_id"
                              autoComplete="role_id"
                              onChange={(e) => {
                                handleChange(e, "role_id");
                              }}
                              value={info?.role_id}
                              className={`mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-base ${error?.role_id && "border-red-600"
                                }`}
                            >
                              <option>---Selecione Papel---</option>
                              {roleList &&
                                roleList.map((singleRole) => {
                                  return (
                                    <option value={singleRole.id}>
                                      {singleRole.name}
                                    </option>
                                  );
                                })}
                            </select>
                            {error?.role_id && (
                              <span className="text-xs text-red-600 capitalize">
                                {error?.role_id}
                              </span>
                            )}
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="password"
                              className="block text-base text-gray-700"
                            >
                              Senha <span className="mandatory">*</span>
                            </label>
                            <input
                              type="password"
                              name="password"
                              id="password"
                              autoComplete="password"
                              onChange={(e) => {
                                handleChange(e, "password");
                              }}
                              value={info?.password}
                              className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-base ${error?.password && "border-red-600"
                                }`}
                            />
                            {error?.password && (
                              <span className="text-xs text-red-600 capitalize">
                                {error?.password}
                              </span>
                            )}
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="cpassword"
                              className="block text-base text-gray-700"
                            >
                              Confirme sua senha{" "}
                              <span className="mandatory">*</span>
                            </label>
                            <input
                              type="password"
                              name="cpassword"
                              id="cpassword"
                              autoComplete="cpassword"
                              onChange={(e) => {
                                handleChange(e, "cpassword");
                              }}
                              value={info?.cpassword}
                              className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-base ${error?.cpassword && "border-red-600"
                                }`}
                            />
                            {error?.cpassword && (
                              <span className="text-xs text-red-600 capitalize">
                                {error?.cpassword}
                              </span>
                            )}
                          </div>
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="last-name"
                              className="block text-base text-gray-700"
                            >
                              Foto de Perfil
                            </label>
                            <div className="mt-1 flex items-center">
                              <img
                                className="inline-block h-12 w-12 rounded-full"
                                src={
                                  typeof info?.profile === "object"
                                    ? URL.createObjectURL(info?.profile)
                                    : "/img/defaultAvatar.svg"
                                }
                                onError={(e) =>
                                  (e.target.src = "/img/defaultAvatar.svg")
                                }
                                alt=""
                              />

                              <div className="ml-4 flex">
                                <div className="relative flex cursor-pointer items-center rounded-md border border-blue-gray-300 bg-white py-2 px-3 shadow-sm focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-500 focus-within:ring-offset-2 focus-within:ring-offset-blue-gray-50 hover:bg-blue-gray-50">
                                  <label
                                    htmlFor="user-photo"
                                    className="pointer-events-none relative text-base text-blue-gray-900"
                                  >
                                    <span>Upload Picture</span>
                                    <span className="sr-only"> user photo</span>
                                  </label>
                                  <input
                                    id="user-photo"
                                    name="user-photo"
                                    type="file"
                                    // onChange={(e) => {
                                    //   setInfo({
                                    //     ...info,
                                    //     avatar: e?.target?.files[0],
                                    //   });
                                    // }}
                                    onChange={(e) => handleChange(e, "profile")}
                                    className="absolute inset-0 h-full w-full cursor-pointer rounded-md border-gray-300 opacity-0"
                                  />
                                </div>
                                <button
                                  type="button"
                                  onClick={handleProfileRemove}
                                  className="ml-3 rounded-md border border-transparent bg-transparent py-2 px-3 text-base text-blue-gray-900 hover:text-blue-gray-700 focus:border-blue-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-blue-gray-50"
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="status"
                              className="block text-base text-gray-700"
                            >
                              Área <span className="mandatory">*</span>
                            </label>
                            <select
                              id="area_id"
                              name="area_id"
                              autoComplete="area_id"
                              onChange={(e) => {
                                handleChange(e, "area_id");
                              }}
                              value={info?.area_id}
                              className={`mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-base ${error?.area_id && "border-red-600"
                                }`}
                            >
                              <option>---Selecione Área---</option>
                              {areaList &&
                                areaList.map((singleRole) => {
                                  return (
                                    <option value={singleRole.id}>
                                      {singleRole.name}
                                    </option>
                                  );
                                })}
                            </select>
                            {error?.area_id && (
                              <span className="text-xs text-red-600 capitalize">
                                {error?.area_id}
                              </span>
                            )}
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="status"
                              className="block text-base text-gray-700"
                            >
                              Estado <span className="mandatory">*</span>
                            </label>
                            <select
                              id="status"
                              name="status"
                              autoComplete="status"
                              onChange={(e) => {
                                handleChange(e, "status");
                              }}
                              value={info?.status}
                              className={`mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-base ${error?.status && "border-red-600"
                                }`}
                            >
                              <option>---Selecione Estado---</option>
                              <option value="Active">Activo</option>
                              <option value="Inactive">Inactivo</option>
                            </select>
                            {error?.status && (
                              <span className="text-xs text-red-600 capitalize">
                                {error?.status}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end">
                    <BtnRedirect path={"/users/ViewUsers"} title={"Cancelar"} />
                    <BtnSaveRecord
                      path={"/master/users"}
                      title={"Submeter"}
                      handleSubmit={handleSubmit}
                    />
                  </div>
                </form>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
